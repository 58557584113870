@import '~antd/dist/antd.less';
@import url('https://fonts.googleapis.com/css2?family=Mountains+of+Christmas:wght@700&display=swap');

:root {
  --window-height: 800px;
}

.snow-wrapper {
  height: 100%;
}

.background {
  background-image: url('/img/background.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  min-height: var(--window-height);
  overflow: hidden;
  position: relative;
}

.background-attribution {
  color: #7A7D50;
  position: absolute;
  bottom: 10px;
  right: 20px;

}

.background-attribution > a {
  color: #7A7D50;
}

.background-attribution > a:active {
  color: #7A7D50;
}

.layout {
  height: 100%;
  min-height: var(--window-height);
}

.content {
  position: relative;
  z-index: 1;
}

.content-card {
  margin: 50px auto;
  max-height: 700px;
  overflow-y: auto;
  overflow-x: hidden;
}

.content-card-small {
  max-width: 500px;
}

.content-card-full {
  max-width: 1200px;
}

.pageHeader {
  background: white !important;
  border-bottom: 1px solid #f0f0f0;
  z-index: 1;
  display: inline-flex;
}

.logo {
  font-family: 'Mountains of Christmas', cursive;
  font-size: 1.5rem;
  font-weight: bold;
  color: #D25D4C;
}

.notification {
  margin-top: 50px;
}

.menu {
  margin-left: auto;
}

.pageFooter {
  text-align: center;
  background-color: white;
  border-top: 1px solid #f0f0f0;
  z-index: 1;
}

.form-submit {
  text-align: end;
  margin-bottom: 0;
}

.register-description {
  margin-bottom: 20px;
}

.header-gif {
  text-align: end;
}

.header-gif > iframe {
  margin-left: 50%;
  transform: translateX(-50%);
}

.header-gif > p {
  padding-right: 15px;
}

.loading {
  text-align: center;
  margin: 50px 0;
}

.disclaimer-link {
  padding: 0;
}

.hidden {
  visibility: hidden;
}

@primary-color: #7A7D50;@border-color-base: #7A7D50;